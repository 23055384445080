import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

export const MATERIAL_MODULES = [
	MatButtonModule,
	MatToolbarModule,
	MatCardModule,
	MatIconModule,
	MatTableModule,
	MatMenuModule,
	MatPaginatorModule,
	MatFormFieldModule,
	MatDividerModule,
	MatInputModule,
	MatDialogModule,
	MatSelectModule,
	MatProgressSpinnerModule,
	MatChipsModule,
];

@NgModule({
	declarations: [],
	imports: [...MATERIAL_MODULES],
	exports: [...MATERIAL_MODULES],
})
export class MaterialModule {}
