import { Injectable } from '@angular/core';
import { ITelemetryPublisher } from '../ITelemetryPublisher';

@Injectable({
	providedIn: 'root',
})
export class ConsoleTelemetryPublisher implements ITelemetryPublisher {
	logEvent(
		name: string,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		// eslint-disable-next-line no-console
		console.info(`[event] ${name}`, properties);
	}

	logException(
		exception: Error,
		severityLevel?: number | undefined,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		// eslint-disable-next-line no-console
		console.error(`[exception] ${exception.message}`, {
			severityLevel,
			properties,
		});
	}

	logTrace(
		message: string,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		// eslint-disable-next-line no-console
		console.info(`[trace] ${message}`, properties);
	}

	logMetric(
		name: string,
		average: number,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		// eslint-disable-next-line no-console
		console.info(`[metric] ${name} = ${average}`, properties);
	}

	logPageView(
		name?: string | undefined,
		url?: string | undefined,
		properties?: { [key: string]: unknown } | undefined,
		measurements?: { [key: string]: number } | undefined,
		duration?: number | undefined,
	): void {
		// eslint-disable-next-line no-console
		console.info(`[pageview] '${url}' => '${name}'`, {
			properties,
			measurements,
			duration,
		});
	}
}
