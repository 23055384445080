import { InjectionToken, inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ITelemetryPublisher } from '../ITelemetryPublisher';

export const APPINSIGHTS_INSTANCE = new InjectionToken<ApplicationInsights>(
	'AppInsightsInstance',
);

export const APPINSIGHTS_CONNECTION_STRING = new InjectionToken<string>(
	'AppInsightsConnectionString',
);

export class ApplicationInsightsTelemetryPublisher
	implements ITelemetryPublisher
{
	/**
	 * @param appInsights The ApplicationInsights instance to use for logging.
	 *
	 * By injecting the instance, it is easier to test this class.
	 */
	private appInsights = inject(APPINSIGHTS_INSTANCE);

	constructor() {
		this.appInsights.loadAppInsights();
	}

	logEvent(name: string, properties?: { [key: string]: never }) {
		this.appInsights.trackEvent({ name: name }, properties);
	}

	logException(
		exception: Error,
		severityLevel?: number | undefined,
		properties?: { [key: string]: unknown } | undefined,
	): void {
		this.appInsights.trackException(
			{
				exception: exception,
				severityLevel: severityLevel,
			},
			properties,
		);
	}

	logMetric(
		name: string,
		average: number,
		properties?: { [key: string]: never },
	) {
		this.appInsights.trackMetric(
			{ name: name, average: average },
			properties,
		);
	}

	logPageView(
		name?: string | undefined,
		url?: string | undefined,
		properties?: { [key: string]: unknown } | undefined,
		measurements?: { [key: string]: number } | undefined,
		duration?: number | undefined,
	): void {
		this.appInsights.trackPageView({
			name: name,
			uri: url,
			properties: {
				duration,
				...properties,
			},
			measurements: measurements,
		});
	}

	logTrace(message: string, properties?: { [key: string]: never }) {
		this.appInsights.trackTrace({ message: message }, properties);
	}
}
