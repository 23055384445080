import { Provider } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { TELEMETRY_PUBLISHER } from '../TELEMETRY_PUBLISHER';
import {
	APPINSIGHTS_CONNECTION_STRING,
	APPINSIGHTS_INSTANCE,
	ApplicationInsightsTelemetryPublisher,
} from './application-insights.telemetry-provider';

/**
 * Builds providers that support the use of Application Insights.
 *
 * Defines the following providers:
 * - `APPINSIGHTS_CONNECTION_STRING` - The connection string for Application Insights.
 * - `APPINSIGHTS_INSTANCE` - The Application Insights instance.
 * - `TELEMETRY_PUBLISHER` - A TelemetryPublisher provided with multi.
 * @param connectionString Application Insights connection string
 * @returns Providers that support the use of Application Insights.
 */
export const withAppInsightsPublisher = (
	connectionString: string,
): Provider[] => {
	return [
		{
			provide: APPINSIGHTS_CONNECTION_STRING,
			useValue: connectionString,
		},
		{
			provide: APPINSIGHTS_INSTANCE,
			deps: [APPINSIGHTS_CONNECTION_STRING],
			useFactory: (connectionString: string) => {
				return new ApplicationInsights({
					config: {
						connectionString,
					},
				});
			},
		},
		{
			provide: TELEMETRY_PUBLISHER,
			useClass: ApplicationInsightsTelemetryPublisher,
			multi: true,
		},
	];
};
