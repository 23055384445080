import { LogLevel } from '@azure/msal-browser';
import { Policy } from '../enums/policy.enum';
import { TenantId } from '../enums/tenant-id.enum';
import { ClientB2C } from '../types/ClientB2C';
import { ClientEnvironment } from '../types/ClientEnvironment';

import { withAppInsightsPublisher } from '@shared/lib/services/telemetry/application-insights/withAppInsightsPublisher';
import { withConsolePublisher } from '@shared/lib/services/telemetry/console/withConsolePublisher';

export const b2c: ClientB2C = {
	logLevel: LogLevel.Warning,
	clientId: '0d1916f9-c1e7-4b8c-9bf0-171b5d11e70c',
	tenantId: TenantId.Staging,
	tenantDomain: `testulplatform.onmicrosoft.com`,
	authorityDomain: 'testulplatform.b2clogin.com',
	policies: {
		[Policy.SignUpSignIn]: 'B2C_1A_Signup_Signin_Staging',
		[Policy.ResetPassword]: 'B2C_1A_Password_Reset_Staging',
		[Policy.EditProfile]: 'B2C_1A_Edit_Profile_Staging',
	},
	redirects: {
		login: '/auth/sign-in',
		logout: '/auth/sign-out',
	},
	scope: 'openid profile offline_access', // Important - Include offline_access to also request a refresh token
	scopes: {
		read: 'https://testulplatform.onmicrosoft.com/splash-dev/Read',
		write: 'https://testulplatform.onmicrosoft.com/splash-dev/Write',
	},
	// signUpSignInPolicyName: 'B2C_1A_Signup_Signin_Staging',
	// passwordResetPolicyName: 'B2C_1A_Password_Reset_Staging',
	// forgotPasswordPolicyName: 'b2c_1a_forgot_password_development',
	// changeEmailAddressPolicyName: 'b2c_1a_change_email_address_development',
	// authorityBase:
	// 	'https://testulplatform.b2clogin.com/testulplatform.onmicrosoft.com',
	//migrationOnDemandClientId: '267459b7-0916-4a0b-9a3c-734a06932c8e',
	// loginRedirectUri: '/auth/sign-in', // Change this to the redirect URI for your app registration for signing in
	// logoutRedirectUri: '/auth/sign-out',
};

export const protectedResourceMap = new Map<string, string[]>([
	['/fieldsprinklertesting/', [b2c.scopes.read]],
	['https://sentinel.qa.ul.com/*', [b2c.scopes.read]],
	['/api/Registration', [b2c.scopes.write]],
]);

export const environment: ClientEnvironment = {
	production: false,
	telemetry: [
		withConsolePublisher(),
		withAppInsightsPublisher(
			'InstrumentationKey=08d9a23a-2d98-4959-aada-a2f04319d0a0;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/;ApplicationId=0cc20077-09f0-4f5d-a202-0b52271eb599',
		),
	],
	routes: [],
	validateB2CTokenUrl: 'https://sentinel.qa.ul.com/Issue/ValidateB2CToken',
	getCompanyByUserUrl: 'https://sentinel.qa.ul.com/Issue/GetCompanyByUser ',
	getSymphonyTokenUrl:
		'https://sentinel.qa.ul.com/Issue/GetPssTokenForSplash ',
	eformsUrl:
		'https://qaeforms.ul.com/?tokens=2&IsPSS=1&clientid=999999893&clientname=Splash&isrelated=true',

	fromemail: 'noreply@notify-staging.ulplatform.com',
	toemail: ['splash_reg_to@hizy2jrw.mailosaur.net'],
	bccEmail: ['nbk.fieldsprinkler@hizy2jrw.mailosaur.net'],
	helpResource: {
		defaultUrl:
			'https://www.ul.com/resources/apps/field-sprinkler-testing-tool',
		registrationPageUrl:
			'https://www.ul.com/resources/apps/field-sprinkler-testing-tool#:~:text=Frequently%20asked%20questions',
		registrationSuccessPageUrl:
			' https://www.ul.com/resources/apps/field-sprinkler-testing-tool#show-modal',
		landingPageUrl:
			'https://www.ul.com/services/fire-sprinkler-testing-and-certification',
	},
	contactus: {
		enable: true,
		allowFileUpload: false,
	},
	azure: {
		appInsights: {
			connectionString:
				'InstrumentationKey=7b8710ce-86b8-4cfd-9f99-667a4eb01fbe;IngestionEndpoint=https://eastus-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
		},
	},
	wp: {
		baseUrl: 'https://uat.my.ul.com/',
		slug: 'splash',
	},
	enableBanner:true
};
