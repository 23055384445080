import { Provider } from '@angular/core';
import { TELEMETRY_PUBLISHER } from '../TELEMETRY_PUBLISHER';
import { ConsoleTelemetryPublisher } from './console.telemetry-provider';

/**
 * Builds providers that support the use of the console.
 *
 * Defines the following providers:
 * - `TELEMETRY_PUBLISHER` - A TelemetryPublisher provided with multi.
 * @returns Provider array that provides ConsoleTelemetryPublisher as a multi provider.
 */
export const withConsolePublisher = (): Provider[] => {
	return [
		{
			provide: TELEMETRY_PUBLISHER,
			useClass: ConsoleTelemetryPublisher,
			multi: true,
		},
	];
};
